@use "../sass/variables";
@import "../sass/mixins";

.flex {
  display: flex;
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

.aie {
  align-items: flex-end;
}

.jcc {
  justify-content: center;
}

.jce {
  justify-content: end;
}

.asc {
  align-self: center;
}

.jcsb {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.w-full {
  width: 100%;
}

.w-45 {
  width: 45%;
}

.h-full {
  height: 100%;
}

.m-0 {
  margin: 0;
}
.m-t {
  margin-top: 30px;
}
.m-b {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0;
}

.f100 {
  flex-basis: 100%;
}

.fauto {
  flex: auto;
}
.mt-auto {
  margin-top: auto;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.gp-10 {
  gap: 10px;
}

.gp-40 {
  gap: 40px;
}

.gp {
  gap: 20px;
}
.scroll-small {
  max-height: 430px;
  overflow: auto;
  padding-right: 10px;
}
.italic {
  margin-top: 2px;
  font-size: 12px;
  font-style: italic;
}

.bd-bt {
  margin-top: -10px;
  margin-bottom: 30px;
  border-bottom: 1px solid $borderColor;
}
.chip{
  background-color: #982068;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 10px;
  margin-left: 7px;
}
@include btn(fill);
@include btn(outline);
@include tab-btn(tab-active);
@include tab-btn(tab-inactive);

.custom-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error {
  font-size: 12px;
  color: #ff4d4f;
  margin-top: 6px;
}

/*switch*/
.ant-switch {
  background: $borderColor;
  &-checked {
    background: variables.$linkColor;
    &:focus {
      box-shadow: none;
    }
  }
  &-handle:before {
    background: $baseColor;
  }
}

/* Scroll*/

.scrollBar {
  max-height: 400px;
  overflow-y: auto;
  display: block;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec;
  // border: 0.3px solid #919191;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ececec;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: $green;
}

.ant-progress-inner {
  background-color: $borderColor;
}

.ant-table-filter-dropdown {
  display: none;
}
.ant-table-filter-trigger:hover {
  color: #bfbfbf;
  background: transparent;
}
.ant-table-filter-trigger {
  cursor: default;
}
.ant-notification-notice {
  border-radius: 6px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
  line-height: 1.3;
  margin-left: 40px;
}
.ant-notification-notice-close {
  right: 10px;
  top: 21px;
}

.ant-empty-normal {
  height: 40px;
}

.ant-empty-normal .ant-empty-image {
  display: none;
}

.ant-empty-description {
  display: none;
}

// cursor pointers class
.cursor-pointer {
  cursor: pointer;
}
.cursor-disable {
  cursor: not-allowed;
}

.normal-font-size {
  font-size: 12px;
  font-family: Font-Regular;
}

//box with white, padding, border, and shadow (Use in compenent detail.)
.basic-detail {
  &-title {
    @include font_size_color(14px, variables.$tabdetail, null, 17px, Font-Medium);
    margin-bottom: 20px;
    padding: 0;
  }
  &-subtitle {
    @include font_size_color(14px, variables.$tabdetail, normal, 17px, Font-Medium);
  }

  &-content {
    background: variables.$baseColor;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    &.gp-top-20 {
      margin-top: 20px;
    }
    &.border {
      border: 1px solid variables.$CardBorderColor;
    }
  }
}

.no-padding {
  padding: 0px !important;
}

.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-0 {
  padding-bottom: 0;
}
.no-bd-bt {
  border-bottom: 0px;
  padding-bottom: 0px;
}
.p-block {
  padding: 15px 0;
}
.table-heading {
  @include font_size_color(14px, variables.$tabdetail, null, null);
  font-family: Font-Medium;
}

.text_ellipsis {
  @include text_ellipsis;
}
